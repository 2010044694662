<template>
    <div>
        <div class="search">
            <div class="enter">
                <div class="category">
                    <el-dropdown trigger="click" placement="bottom" @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{typeText}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in domwList" :key="item.id">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <input type="text" placeholder="请输入关键字搜帖子" v-model="form.key" class="input">
                <button class="enter-btn" @click="search">搜索</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            form: {
                key: "",
                classifyId: 0
            },
            typeText: '类别',
            domwList: [],
        };
    },

    mounted() {
        this.getClass()
    },

    methods: {
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                console.log(res);
                this.domwList = res.data

            })
        },
        search() {
            this.$util.post('/forum/search', this.form).then(res => {
                this.searchList = res.data
                this.$router.push({ path: `/forumSearch?form=${JSON.stringify(res.data)}` })
                this.$emit('search', this.form);
                console.log(res);
            })

        },
        handleCommand(item) {
            console.log(item);
            this.typeText = item.name
            this.form.classifyId = item.id
        }
    },
};
</script>

<style lang="scss" scoped>
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto 30px;
    .enter {
        width: 820px;
        height: 50px;
        display: flex;
        align-items: center;
        border: 1px solid #e40012;
        .category {
            width: 100px;
            // height: 100%;
            text-align: center;
            border-right: 1px solid #c4c4c4;
        }
        .input {
            flex: 1;
            padding: 0 30px;
            height: 36px;
            border: none;
            outline: none;
        }
        .enter-btn {
            width: 150px;
            height: 100%;
            background: #e40012;
            border: none;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
        }
    }
    .release {
        margin-left: 30px;
        width: 150px;
        height: 50px;
        border: 1px solid #f1f1f1;
        color: #e40012;
        cursor: pointer;
        background: #fff;
        font-size: 16px;
    }
}
</style>